<template>
  <div :class="`${className} text-center`" style="top: 40%;">
    <div
      v-if="option"
      class="row items-center justify-center"
      style="height: 160px; width: 100%;"
    >
      <img
        v-if="option.mediumImage"
        :src="option.mediumImage"
        :alt="option.title"
        class="fit"
        style="object-fit: contain;"
      >
    </div>

    <p v-if="option" class="q-my-xs text-h6">
      {{ $t(option.title) }}
    </p>

    <slot />
  </div>
</template>

<script>
// Utils
import { getOption } from '../../routes'

export default {
  name: 'Jumbotron',
  props: {
    position: {
      type: String,
      default () {
        return 'center'
      }
    }
  },
  data () {
    return {
      option: getOption(this.$route.fullPath) || getOption('/terminal/items-info')
    }
  },
  computed: {
    className () {
      if (this.position === 'center') {
        return 'absolute-center'
      }

      return ''
    }
  }
}
</script>
